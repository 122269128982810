import React, { useEffect, useState } from 'react';

import '../Designe/Weldings.css'

import felulet1 from '../img/webheg/felulet1.jpeg'
import felulet2 from '../img/webheg/felulet2.jpeg'
import gyok1 from '../img/webheg/gyok1.jpeg'
import gyok2 from '../img/webheg/gyok2.jpeg'
import gyar1 from '../img/webheg/gyar1.jpeg'
import gyar2 from '../img/webheg/gyar2.jpeg'

import { useSelector, useDispatch } from 'react-redux';

import translations from '../strings/Strings'

export default function Weldings() {

  const language = useSelector((state) => state.language);
  const [strings,setStrings] = useState(translations.hun)
  useEffect(() => {
    // Itt lehet kezelni a nyelv állapotváltozásait
    console.log('Nyelv megváltozott:', language);
    if(language=== 'en'){
      setStrings(translations.en)
    }else{
      setStrings(translations.hun)
    }
  }, [language]);



  if(window.innerWidth > 762){
  return (
    <div className="full-screen-container" id="weldings">
    <h1 className="title">{strings.Weldings_title}</h1>
    <div className="hasab">
    <div className="felirat-left">{strings.Weldings_seams}</div>
        <div className="felirat-middle">{strings.Weldings_root}</div>
        <div className="felirat-right">{ strings.Weldings_factory }</div>
      </div>
    <div className="image-container">
      <img src={felulet1} alt="Orbitális hegesztés" style={{width: window.innerWidth/5, height: window.innerHeight/4}}/>
      <img src={gyok1} alt="Tökéletes gyök oldali hegesztés"style={{width: window.innerWidth/5, height: window.innerHeight/4}} />
      <img src={gyar1} alt="Hegesztő előgyártási munkák" style={{width: window.innerWidth/5, height: window.innerHeight/4}}/>
    </div>
    <div className="image-container">
      <img src={felulet2} alt="Orbitális hegesztés" style={{width: window.innerWidth/5, height: window.innerHeight/4}}/>
      <img src={gyok2} alt="Tökéletes gyök oldali hegesztés" style={{width: window.innerWidth/5, height: window.innerHeight/4}}/>
      <img src={gyar2} alt="Hegesztő előgyártási munkák" style={{width: window.innerWidth/5, height: window.innerHeight/4}}/>
    </div>
  </div>
  )}else{
    return (
      <div className="full-screen-container" id="weldings">
      <h1 className="title">{strings.Weldings_title}</h1>
      <div className="hasab">
      <div className="felirat-left">{strings.Weldings_seams}</div>
          <div className="felirat-middle">{strings.Weldings_root}</div>
          <div className="felirat-right">{ strings.Weldings_factory }</div>
        </div>
      <div className="image-container">
        <img src={felulet1} alt="Orbitális hegesztés" style={{width: window.innerWidth/4, height: window.innerHeight/5}}/>
        <img src={gyok1} alt="Tökéletes gyök oldali hegesztés"style={{width: window.innerWidth/4, height: window.innerHeight/5}} />
        <img src={gyar1} alt="Hegesztő előgyártási munkák" style={{width: window.innerWidth/4, height: window.innerHeight/5}}/>
      </div>
      <div className="image-container">
        <img src={felulet2} alt="Orbitális hegesztés" style={{width: window.innerWidth/4, height: window.innerHeight/5}}/>
        <img src={gyok2} alt="Tökéletes gyök oldali hegesztés" style={{width: window.innerWidth/4, height: window.innerHeight/5}}/>
        <img src={gyar2} alt="Hegesztő előgyártási munkák" style={{width: window.innerWidth/4, height: window.innerHeight/5}}/>
      </div>
    </div>
    )
  }
}
