import React, { useState, useEffect } from 'react';
import sreenshot1 from '../img/screenshot_1.jpeg';
import telefon from '../img/webheg/telefon2.png';
import '../Designe/Application.css';

import next from '../img/next.png'
import back from '../img/back.png'

import scrollToSection from './ScrollToSection';

import { useSelector, useDispatch } from 'react-redux';

import translations from '../strings/Strings'

export default function Application() {

  const language = useSelector((state) => state.language);
  const [strings,setStrings] = useState(translations.hun)

  const parametes=[
    {
      titel: strings.Application_Settings_Start_Welder_title,
      describe: strings.Application_Settings_Start_Welder_desc
    },
    {
      titel: strings.Application_Settings_Delay_title,
      describe: strings.Application_Settings_Delay_desc
    },
    {
      titel: strings.Application_Settings_Rotation_Speed_title,
      describe: strings.Application_Settings_Rotation_Speed_desc
    },
    {
      titel: strings.Application_Settings_OverRun_title,
      describe: strings.Application_Settings_OverRun_desc
    },
    {
      titel: strings.Application_Settings_Stop_Impulse_title,
      describe: strings.Application_Settings_Stop_Impulse_desc
    },
    {
      titel: strings.Application_Settings_Stop_Welder_Impulse_title,
      describe: strings.Application_Settings_Stop_Welder_Impulse_desc
    },
    {
      titel: strings.Application_Settings_Rotation_Direction_title,
      describe: strings.Application_Settings_Rotation_Direction_desc
    },
    {
      titel: strings.Application_Settings_Meta_title,
      describe: strings.Application_Settings_Meta_desc
    },
  ]

  const [activeDiv, setActiveDiv] = useState(1);
  const [Title, setTitle] = useState(parametes[0].titel);
  const [Description, setDescription] = useState(parametes[0].describe);
  const [Index, setIndex] = useState(0);



  useEffect(() => {

    if(language=== 'en'){
      setStrings(translations.en)
      setTitle(translations.en.Application_Settings_Start_Welder_title)
      setDescription(translations.en.Application_Settings_Start_Welder_desc)
      setIndex(0)
    }else{
      setStrings(translations.hun)
      setTitle(translations.hun.Application_Settings_Start_Welder_title)
      setDescription(translations.hun.Application_Settings_Start_Welder_desc)
      setIndex(0)
    }
  }, [language]);

  


  const handleDecrementClick = () => {

    const newIndex = Index - 1;

    if(newIndex >= 0){
      setIndex(newIndex);
      setTitle(parametes[newIndex].titel);
      setDescription(parametes[newIndex].describe);
    }
  };

  const handleIncrementClick = () => {
    const newIndex = Index + 1;
    if(newIndex < parametes.length){
      setIndex(newIndex);
      setTitle(parametes[newIndex].titel);
      setDescription(parametes[newIndex].describe);
    }
  };

  const handleDivChange = (divNumber) => {
    setActiveDiv(divNumber);
  };


    var title_fontsize = 100;
    var description_fontsize = 140;

    if(window.innerHeight > 1000){
     title_fontsize = 110;
     description_fontsize = 120;
    }



  if(window.innerWidth>762){
  return (
    <div className="container" id="app">
      <div className="left-column">

        <div className="application-title">
          <h2 style={{fontSize: window.innerHeight/25}}>{strings.Application_title}</h2>
        </div>

        <div className="scrol_buttons">
          <button
            onClick={() => handleDivChange(1)}
            className={`button ${activeDiv === 1 ? 'active' : ''}`}
            style={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20, fontSize: window.innerWidth/120 }}
          >
            {strings.Application_Settings_title}
          </button>
          <button
            onClick={() => handleDivChange(2)}
            className={`button ${activeDiv === 2 ? 'active' : ''}`}
            style={{fontSize: window.innerWidth/120 }}
          >
            {strings.Application_Inquery_title}
          </button>
          <button
            onClick={() => handleDivChange(3)}
            className={`button ${activeDiv === 3 ? 'active' : ''}`}
            style={{ borderTopRightRadius: 20, borderBottomRightRadius: 20, fontSize: window.innerWidth/120 }}
          >
            {strings.Application_Save_BT_title}
          </button>
        </div>
        {activeDiv === 1 && (
      <div className='setup-container'>
        <div className='setup-div'>
          <div className='setup-row' >
            <div className="card">
              <div className="card-content">
                <h3 className="card-title" style={{fontSize: window.innerWidth/title_fontsize}}>{strings.Application_Settings_Start_Welder_title}</h3>
                <p className="card-description" style={{fontSize: window.innerWidth/description_fontsize}}>
                {strings.Application_Settings_Start_Welder_desc}
                </p>
                <button style={{fontSize: window.innerWidth/title_fontsize}} onClick={() => scrollToSection('contact')} className="card-button">{strings.Application_Get_More_Information}</button>
              </div>
            </div>
          </div> 
          <div className='setup-row' >
            <div className="card">
              <div className="card-content">
                <h3 className="card-title" style={{fontSize: window.innerWidth/title_fontsize}}> {strings.Application_Settings_Delay_title} </h3>
                <p className="card-description" style={{fontSize: window.innerWidth/description_fontsize}}>
                {strings.Application_Settings_Delay_desc}
                </p>
                <button onClick={() => scrollToSection('contact')} className="card-button">{strings.Application_Get_More_Information}</button>
              </div>
            </div>
          </div>
          <div className='setup-row' >
            <div className="card">
              <div className="card-content">
                <h3 className="card-title" style={{fontSize: window.innerWidth/title_fontsize}}> {strings.Application_Settings_Rotation_Speed_title} </h3>
                <p className="card-description" style={{fontSize: window.innerWidth/description_fontsize}}>
                {strings.Application_Settings_Rotation_Speed_desc}
                </p>
                <button onClick={() => scrollToSection('contact')} className="card-button">{strings.Application_Get_More_Information}</button>
              </div>
            </div>
          </div>
        </div>
        <div className='setup-div'>
          <div className='setup-row' >
            <div className="card">
              <div className="card-content">
                <h3 className="card-title" style={{fontSize: window.innerWidth/title_fontsize}}>{strings.Application_Settings_OverRun_title}</h3>
                <p className="card-description" style={{fontSize: window.innerWidth/description_fontsize}}>
                {strings.Application_Settings_OverRun_desc}
                </p>
                <button onClick={() => scrollToSection('contact')} className="card-button">{strings.Application_Get_More_Information}</button>
              </div>
            </div>
          </div>
          <div className='setup-row' >
            <div className="card">
              <div className="card-content">
                <h3 className="card-title" style={{fontSize: window.innerWidth/title_fontsize}}>{strings.Application_Settings_Stop_Impulse_title}</h3>
                <p className="card-description" style={{fontSize: window.innerWidth/description_fontsize}}>
                {strings.Application_Settings_Stop_Impulse_desc}
                </p>
                <button onClick={() => scrollToSection('contact')} className="card-button">{strings.Application_Get_More_Information}</button>
              </div>
            </div>
          </div>
          <div className='setup-row' >
            <div className="card">
              <div className="card-content">
                <h3 className="card-title" style={{fontSize: window.innerWidth/title_fontsize}}> {strings.Application_Settings_Stop_Welder_Impulse_title} </h3>
                <p className="card-description" style={{fontSize: window.innerWidth/description_fontsize}}>
                {strings.Application_Settings_Stop_Welder_Impulse_desc}
                </p>
                <button onClick={() => scrollToSection('contact')} className="card-button">{strings.Application_Get_More_Information}</button>
              </div>
            </div>
          </div>
        </div>
        <div className='setup-div'>
          <div className='setup-row' >
            <div className="card">
              <div className="card-content">
                <h3 className="card-title" style={{fontSize: window.innerWidth/title_fontsize}}>{strings.Application_Settings_Rotation_Direction_title}</h3>
                <p className="card-description" style={{fontSize: window.innerWidth/description_fontsize}}>
                {strings.Application_Settings_Rotation_Direction_desc}
                </p>
                <button onClick={() => scrollToSection('contact')} className="card-button">{strings.Application_Get_More_Information}</button>
              </div>
            </div>
          </div>
          <div className='setup-row' >
            <div className="card">
              <div className="card-content">
                <h3 className="card-title" style={{fontSize: window.innerWidth/title_fontsize}}>{strings.Application_Settings_Meta_title}</h3>
                <p className="card-description" style={{fontSize: window.innerWidth/description_fontsize}}>
                {strings.Application_Settings_Meta_desc}
                </p>
                <button onClick={() => scrollToSection('contact')} className="card-button">{strings.Application_Get_More_Information}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
        )}

       {activeDiv === 2 && (
        <div className='lekerdezes-cont'>
            <div className='lekerdezes'>
                <p>{strings.Application_Inquery_desc}</p>
            </div>
        </div>
        )}

        {activeDiv === 3 && (
        <div className='lekerdezes-cont'>
            <div className='lekerdezes'>
                <h2>{strings.Application_Save_title}</h2>
                <p> {strings.Application_Save_desc}
                </p>
            </div>
        </div>
        )}

      </div>
      <div className="right-column">
        <img src={telefon} className="screenshot" alt="Hegesztő app" />
      </div>
    </div>
  );}else{
    return(
    <div class="split-screen" id='app'>
      <div class="top-pane">
        <div className='top-pane-title'>
        <h2>{strings.Application_title}</h2>
        </div>
        <div className='top-pane-img'>
        <img src={telefon}/>
          </div>
      </div>
      <div class="bottom-pane">
        <div className='bottom-pane-arrow'>
          <img src={back} onClick={handleDecrementClick} />
        </div>
        <div className='bottom-pane-middle'>
        <div className="card">
              <div className="card-content">
                <h3 style={{fontSize: window.innerHeight/50}} className="card-title">{Title}</h3>
                <p style={{fontSize: window.innerHeight/55}} className="card-description">
                {Description}
                </p>
                <button  onClick={() => scrollToSection('contact')} className="card-button">{strings.Application_Get_More_Information}</button>
              </div>
            </div>
        </div>
        <div className='bottom-pane-arrow'>
        <img src={next} onClick={handleIncrementClick}/>
        </div>
      </div>
    </div>
    )
  }
}
