const initialState = {
    language: 'hun',
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_LANGUAGE':
        console.log('Régi nyelv:', state.language);
        console.log('Új nyelv:', action.payload);
        return {
          ...state,
          language: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default reducer;