import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Új sor hozzáadva
import '../Designe/Contact.css'


import location from '../img/footer/location.png'
import phone from '../img/footer/telephone (1).png'
import facebook from '../img/footer/facebook (1).png'
import instagramm from '../img/footer/instagram.png'
import youtube from '../img/footer/youtube.png'
import gmail from '../img/footer/gmail.png'
import shark from '../img/footer/IMG_9394.jpg'

import { useSelector, useDispatch } from 'react-redux';

import translations from '../strings/Strings'

function Contact() {

  const title_size= 50;
  const input_size = 50;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [feedback, setFeedback] = useState({ message: '', type: '' });

  const language = useSelector((state) => state.language);
  const [strings,setStrings] = useState(translations.hun)

  const handleSubmit = (e) => {
    e.preventDefault();
    // Itt a beküldés logikája
  
    // Adatok elküldése a szervernek
    axios
      .post('https://app-ppd7fma2sq-uc.a.run.app/sendmail', {
        to: 'info@orbitalchamp.com', // Ide írd be a célba küldendő email címet
        subject: 'Üzenet az Orbitalchamp.com-ról!',
        message: `
          Név: ${name}
          Email: ${email}
          Cégnév: ${company}
          Telefonszám: ${phoneNumber}
          Üzenet: ${message}
        `,
      })
      .then((response) => {
        console.log('Sikeres email küldés:', response.data.message);
        if(window.innerWidth>762){
          setFeedback({ message: 'Az üzenet sikeresen elküldve!', type: 'success' });
        }else{
          alert('Az üzenet sikeresen elküldve!')
        }
        
      })
      .catch((error) => {
        console.error('Hiba az email küldésekor:', error);
        if(window.innerWidth>762){
        setFeedback({ message: 'Hiba történt az üzenet küldése közben!', type: 'error' });
        }else{
          alert('Hiba történt az üzenet küldése közben!')
        }
      });
  };

  const handleImageClick = (url) => {
    // Kattintáskor átirányítunk a Facebook oldalra
    window.open(url, '_blank');
  };

  const handleEmailClick = () => {
    const emailAddress = 'info@orbitalchamp.com';
    const emailSubject = 'Érdeklődni szeretnék!';
    const emailBody = 'Kedves OrbitalChamp kft!'; // Ide írd a szöveget
    const emailLink = `mailto:${emailAddress}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
    
    window.open(emailLink, '_blank');
  };


  useEffect(() => {
    // Itt lehet kezelni a nyelv állapotváltozásait
    console.log('Nyelv megváltozott:', language);
    if(language=== 'en'){
      setStrings(translations.en)
    }else{
      setStrings(translations.hun)
    }
  }, [language]);


    if(window.innerWidth>762){
    return (
      <div className="contact_footer_container" id='contact'>
      <div className="contact_container">
        <div className="contact_left-column">
          <div className="contact_image-container">
            <img src={shark} alt="Cápák között" style={{width: window.innerHeight/0.8}} />
          </div>
        </div>
        <div className="contact_right-column">
        <div className="contact-header">
          <h2 style={{fontSize: window.innerHeight/25}} >{strings.Contact_title}</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label style={{fontSize: window.innerHeight/title_size}} htmlFor="name">{strings.Contact_name}</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder= {strings.Contact_name}
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{fontSize: window.innerHeight/title_size}} 
              />
            </div>
            <div className="form-group">
              <label style={{fontSize: window.innerHeight/title_size}}  htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{fontSize: window.innerHeight/title_size}} 
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label style={{fontSize: window.innerHeight/title_size}}  htmlFor="company">{strings.Contact_Company_name}</label>
              <input
                type="text"
                id="company"
                name="company"
                placeholder= {strings.Contact_Company_name}
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                style={{fontSize: window.innerHeight/title_size}} 
              />
            </div>
            <div className="form-group">
              <label style={{fontSize: window.innerHeight/title_size}}  htmlFor="phone">{strings.Contact_Phone}</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder= {strings.Contact_Phone}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                style={{fontSize: window.innerHeight/title_size}} 
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group form-group-100">
              <label style={{fontSize: window.innerHeight/title_size}}  htmlFor="message">{strings.Contact_message}</label>
              <textarea
                id="message"
                name="message"
                rows=  "6"
                placeholder={strings.Conatct_message_desc}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{fontSize: window.innerHeight/title_size}} 
              />
            </div>
          </div>
          <div className="button-container">
            <button style={{fontSize: window.innerHeight/title_size}}  type="submit">{strings.Conatct_Send}</button>
          </div>
          {feedback.message && (
          <div className={`feedback_${feedback.type === 'success' ? 'success' : 'error'}`}>
          <p style={{fontSize: window.innerHeight/title_size}}>{feedback.message}</p>
        </div>
       )}
        </form>
        </div>
      </div>
      <div className='footer'>
        <div className='footer-container'>
        <img src={location} alt='OrbitalChamp helyszín Budapest'></img>
        <p>Budapest 1224 XVI. utca 9/a</p>
        </div>
        <div className='footer-container-middle'>
        <img src={facebook} alt='Kövessen minket Facebookon' onClick={()=> handleImageClick('https://www.facebook.com/profile.php?id=100063915306124')} ></img>
        <img src={instagramm} alt='Kövessen minket Instagramon' onClick={()=> handleImageClick('https://www.instagram.com/orbitalchamp2023')}></img>
        <img src={youtube} alt='Nézze meg videóinkat YouTube-on' onClick={()=> handleImageClick('https://www.youtube.com/channel/UC1kGWbqiOiRHCLruU4hNpbA')}></img>
        <img src={gmail} alt='Email küldése az OrbitalChamp számára' onClick={handleEmailClick}></img>
        </div>
        <div className='footer-container'>
        <img src={phone} alt='Kapcsolat OrbitalChamp telefonszámon'></img>
        <p>+36 30 562 2299</p>
        </div>
      </div>
      </div>
      
    );
    }else{
      return(
      <div className="contact_footer_container" id='contact'>
      <div className="contact_container">
        <div className="contact_right-column">
        <div className="contact-header">
          <h2 style={{fontSize: window.innerHeight/20}}>{strings.Contact_title}</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label style={{fontSize: window.innerHeight/input_size}} htmlFor="name">{strings.Contact_name}</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder= {strings.Contact_name}
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{fontSize: window.innerHeight/input_size}}
              />
            </div>
            <div className="form-group">
              <label style={{fontSize: window.innerHeight/input_size}} htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{fontSize: window.innerHeight/input_size}}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label style={{fontSize: window.innerHeight/input_size}} htmlFor="company">{strings.Contact_Company_name}</label>
              <input
                type="text"
                id="company"
                name="company"
                placeholder={strings.Contact_Company_name}
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                style={{fontSize: window.innerHeight/input_size}}
              />
            </div>
            <div className="form-group">
              <label style={{fontSize: window.innerHeight/input_size}} htmlFor="phone">{strings.Contact_Phone}</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder={strings.Contact_Phone}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                style={{fontSize: window.innerHeight/input_size}}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group form-group-100">
              <label style={{fontSize: window.innerHeight/input_size}} htmlFor="message">{strings.Contact_message}</label>
              <textarea
                id="message"
                name="message"
                rows="6"
                placeholder={strings.Conatct_message_desc}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{fontSize: window.innerHeight/input_size}}
              />
            </div>
          </div>
          <div className="button-container">
            <button type="submit">{strings.Conatct_Send}</button>
          </div>
          {feedback.message && (
          <div className={`feedback_${feedback.type === 'success' ? 'success' : 'error'}`}>
          <p style={{fontSize: window.innerHeight/input_size}} >{feedback.message}</p>
        </div>
       )}
        </form>
        </div>
      </div>
      <div className='footer'>
        <div className='footer-container'>
        <img src={location} alt='location'></img>
        <p>Budapest 1224 XVI. utca 9/a</p>
        </div>
        <div className='footer-container-middle'>
        <img src={facebook} alt='location' onClick={()=> handleImageClick('https://www.facebook.com/profile.php?id=100063915306124')} ></img>
        <img src={instagramm} alt='location' onClick={()=> handleImageClick('https://www.instagram.com/orbitalchamp2023')}></img>
        <img src={youtube} alt='location' onClick={()=> handleImageClick('https://www.youtube.com/channel/UC1kGWbqiOiRHCLruU4hNpbA')}></img>
        <img src={gmail} alt='Cápák között' onClick={handleEmailClick}></img>
        </div>
        <div className='footer-container'>
        <img src={phone} alt='location'></img>
        <p>+36 30 562 2299</p>
        </div>
      </div>
      </div>
      
    );
    }
}

export default Contact;
